import styled from 'styled-components';
import drop from './drop.svg';

type Props = {
  fullPage?: boolean;
};

const StyledLoading = styled.div<Props>`
  /* Position fixed to take whole screen */
  position: ${(p) => (p.fullPage ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  /* Above drawers or below the navbar */
  z-index: ${(p) => (p.fullPage ? 1500 : 50)};
  display: flex;
  justify-content: center;
  align-items: ${(p) => (p.fullPage ? 'center' : 'flex-start')};
  padding-top: ${(p) => (p.fullPage ? 0 : '100px')};

  img {
    height: 42px;
    width: 42px;
  }
`;

const Loading: React.FC<Props> = (props) => {
  return (
    <StyledLoading {...props}>
      <img src={drop} alt="Loading..." />
    </StyledLoading>
  );
};

export default Loading;
