import GraphQLProvider from 'contexts/GraphQLProvider';
import Loading from 'components/Loading';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFoundRoute from 'routes/NotFound';

/**
 * Lazy routes
 */
const LoginRoute = lazy(() => import('routes/Login'));

export default function App(): JSX.Element {
  return (
    <Suspense fallback={<Loading fullPage={true} />}>
      <BrowserRouter>
        <GraphQLProvider>
          <Routes>
            <Route index element={<Loading fullPage={true} />} />
            <Route path="/login" element={<LoginRoute />} />
            <Route path="*" element={<NotFoundRoute />} />
          </Routes>
        </GraphQLProvider>
      </BrowserRouter>
    </Suspense>
  );
}
