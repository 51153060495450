import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import type { ReactNode } from 'react';
import { useNavigate } from 'react-router';

const uri = process.env.REACT_APP_API_URI;

type Props = {
  children: ReactNode;
};

export default function GraphQLProvider({ children }: Props) {
  const navigate = useNavigate();

  const client = new ApolloClient({
    link: from([
      onError(({ graphQLErrors, networkError }) => {
        // check for unauthenticated errors and redirect to login if needed
        if (
          graphQLErrors?.find((e) => e.extensions?.code === 'UNAUTHENTICATED')
        ) {
          const { pathname, search, hash } = window.location;

          if (pathname.startsWith('/login')) {
            // already in login page (nothing to do)
            return;
          }

          // Clear the store to remove any user sensitive information.
          // Wait for sensitive information to finish resetting before any
          // redirect, this prevents new requests to be triggered by the
          // redirected page which can cause issues with cache handling.
          client.resetStore().then(() => {
            // because we are redirecting to the login page, we don't want to
            // keep the current URL, so we start with a clean state of
            // searchParams
            const searchParams = new URLSearchParams();
            searchParams.set('t', pathname + search + hash);

            // we keep the hash just in case, but shouldn't be needed either
            navigate(`/login?${searchParams}${hash}`);
          });

          return;
        }

        // default onError implementation as in:
        // https://www.apollographql.com/docs/react/data/error-handling/#advanced-error-handling-with-apollo-link
        graphQLErrors?.forEach(({ message, locations, path }) => {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
        });

        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
        }
      }),
      createUploadLink({ uri, credentials: 'include' }),
    ]),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
